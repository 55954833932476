export type TypeFilters =
  | 'employees'
  | 'patients'
  | 'incidents'
  | 'newIncidents'
  | 'allIncidents'
  | 'admissionsAll'
  | 'admissions'
  | 'hospitals'
  | 'doctors'
  | 'timesheets'
  | 'redFlags'
  | 'reportIncomplete'
  | 'lessons'
  | 'lessonPlans'
  | 'lessonCategories'
  | 'users'
  | 'userEmployees'
  | 'roles'
  | 'employeeDocuments'
  | 'employeeNotes'
  | 'employeeAttachments'
  | 'employeeAttachmentsApp'
  | 'patientAdmissions'
  | 'patientIncidents'
  | 'patientAttachments'
  | 'patientNotes'
  | 'patientTimesheets'
  | 'patientRedFlags'
  | 'patientRedFlagsReports'
  | 'patientsIncidentReport'
  | 'incidentNotes'
  | 'incidentAttachments'
  | 'admissionDetails'
  | 'admissionAttachments'
  | 'admissionAttachmentsApp'
  | 'admissionNotes'
  | 'redFlagAttachments'
  | 'lessonPlanDetails'
  | 'medicalDashboard'
  | 'medicalDocumentsHistory'
  | 'medicalAttachmentsApp'
  | 'medicalAttachments'
  | 'medicalNotes';

export enum PER_PAGE_KEY {
  // main dashboards
  employees = 'dashboard',
  patients = 'patient',
  incidents = 'incident',
  newIncidents = 'incident_new',
  allIncidents = 'incident_all',
  admissionsAll = 'admission_all',
  admissions = 'admission',
  hospitals = 'hospital',
  doctors = 'doctor',
  timesheets = 'timesheet',
  redFlags = 'red_flag',
  reportIncomplete = 'report_incomplete',
  lessons = 'lesson',
  lessonPlans = 'lesson_plan',
  lessonCategories = 'lesson_category',
  users = 'user',
  userEmployees = 'employee',
  roles = 'role',
  medicalDashboard = 'employee_medical_app',
  patientsIncidentReport = 'patient_incident',
  patientRedFlagsReports = 'patient_red_flag',

  // sub tables
  employeeDocuments = 'history',
  employeeNotes = 'employee_note',
  employeeAttachments = 'employee_attachment',
  employeeAttachmentsApp = 'employee_attachment',

  patientAdmissions = 'patient_admission',
  patientIncidents = 'patient_incident',
  patientAttachments = 'patient_attachment',
  patientNotes = 'patient_note',
  patientTimesheets = 'patient_timesheet',
  patientRedFlags = 'patient_red_flag',

  incidentNotes = 'incident_note',
  incidentAttachments = 'incident_attachment',

  admissionDetails = 'admission_detail',
  admissionAttachments = 'admission_attachment',
  admissionAttachmentsApp = 'admission_attachment',
  admissionNotes = 'admission_note',
  redFlagAttachments = 'red_flag_attachment',

  lessonPlanDetails = 'lesson_plan_details',

  medicalDocumentsHistory = 'employee_medical_app_history',
  medicalAttachments = 'employee_medical_app_attachment',
  medicalNotes = 'employee_medical_app_note',
  medicalAttachmentsApp = 'employee_medical_app_attachment',

  testIndex = 'test_index',
  testDetails = 'test_details',
}

export interface FiltersSavingState {
  keys: string[];
  popstate: boolean;
}

export interface BaseFilters {
  page?: number;
  order?: string;
  dir?: string;
  perPage: number;
  search: string;
}

interface DataRange {
  from: string;
  to: string;
}

export interface EmployeeFilter extends BaseFilters {
  statuses: string[];
  caregiverStatuses: string[];
  sales: string[];
  skillCategories: string[];
  assigned: number[];
  date: DataRange;
  aideType?: string[];
  hiring?: string;
}

export interface EmployeeDocumentsFilters extends BaseFilters {
  date: DataRange;
  statuses: string[];
  hhaSync: number;
}

export interface EmployeeNotesFilters extends BaseFilters {
  authors: string[];
}

export interface EmployeeAttachmentsFilters extends BaseFilters {
  withComment: string;
  hhaExchange: string;
}

export interface AdmissionsFilters extends BaseFilters {
  statuses: string[];
  supervisors: string[];
  portalPatientStatuses: string[];
  assigned: string[];
  startDate: string;
  endDate: string;
  type: string[];
}

export interface PatientsFilters extends BaseFilters {
  coordinators: string[];
  patientStatuses: string[];
  portalPatientStatuses: string[];
  assigned: string[];
}

export interface PatientAdmissionsFilters extends BaseFilters {
  statuses: string[];
  supervisors: string[];
  startDate: string;
  endDate: string;
}

export interface PatientIncidentsFilters extends BaseFilters {
  statuses: string[];
  incidentTypes: string[];
  dueDate: string;
}

export interface PatientAttachmentsFilters extends BaseFilters {
  date: DataRange;
  hhaExchange: number;
}

export interface PatientNotesFilters extends BaseFilters {
  reasons: string[];
  authors: string[];
}

export interface PatientTimesheetsFilters extends BaseFilters {
  statuses: string[];
  caregivers: string[];
  date: string;
  patientId: string;
}

export interface PatientRedFlagsFilters extends BaseFilters {
  statuses: string[];
  types: string[];
  lastNoteDate: string;
}

export interface IncidentsFilters extends BaseFilters {
  assigned: any[];
  dueDate: string;
  statuses?: string[];
  type: string[];
  dateFrom: string;
  dateTo: string;
}

export interface PatientIncidentsReportFilters extends BaseFilters {
  assigned: any[];
  dueDate: string;
  statuses: string[];
  incidentsType: string[];
  dateFrom: string;
  dateTo: string;
}

export interface IncidentAttachmentsFilters extends BaseFilters {
  hhaExchange: number;
  date: DataRange;
}

export interface IncidentNotesFilters extends BaseFilters {
  authors: string[];
  notSavePerPage: number;
}

export interface AdmissionDetailsFilter extends BaseFilters {
  supervisors: string[];
  statuses: string[];
  assigned: string[];
  startDate: string;
  endDate: string;
  type: string[];
  syncWithHha: string;
}

export interface AttachmentsFilter extends BaseFilters {
  date: DataRange;
  withComment: string;
  hhaExchange: number | string;
}

export interface TimesheetsFilters extends BaseFilters {
  caregivers: string[] | string;
  statuses: string[] | string;
  dateFrom?: string;
  dateTo?: string;
  patientId?: string;
}

export interface RedFlagsFilters extends BaseFilters {
  status: string[];
  hhaStatus: string[];
  patientStatus: string[];
  type: string[];
  date: DataRange;
  createdAtTo: string;
  createdAtFrom: string;
  lastNotePosted: DataRange;
  retentionSpecialist: string[];
}

export interface PatientRedFlagsReportsFilters extends BaseFilters {
  statuses: string[];
  types: string[];
  createdAtTo: string;
  createdAtFrom: string;
}

export interface LessonsFilters {
  perPage: number;
  page: number;
  categories: string[];
}

export interface LessonPlanDetailsFilters extends BaseFilters {
  isCompleted: number;
}

export interface UsersFilters extends BaseFilters {
  roles: string[];
}

export interface UsersEmployeesFilters extends BaseFilters {
  departments: number[];
}

export interface MedicalAppDashboardFilters extends BaseFilters {
  statuses: string[];
  caregiverStatuses: string[];
  sales: string[];
  skillCategories: string[];
  assigned: number[];
  date: DataRange;
}

export interface MedicalDocumentHistoryFilters extends BaseFilters {
  statuses: string[];
  caregiverStatuses: string[];
  sales: string[];
  skillCategories: string[];
  assigned?: number[];
  date: DataRange;
  hhaSync: number;
}

export interface MedicalAttachmentsFilters extends BaseFilters {
  hhaExchange: string;
}

export interface MedicalNotesFilters extends BaseFilters {
  authors: string[];
}

export const FILTERS = {
  employees: {
    statuses: [],
    caregiverStatuses: [],
    assigned: [],
    date: { from: '', to: '' },
    sales: [],
    search: '',
    skillCategories: [],
    order: '',
    dir: '',
    perPage: 10,
    page: 1,
    aideType: [],
    hiring: '',
  },
  admissions: {
    assigned: [],
    startDate: '',
    endDate: '',
    portalPatientStatuses: [],
    search: '',
    statuses: [],
    supervisors: [],
    type: [],
    order: 'startDate',
    dir: 'desc',
    perPage: 10,
    page: 1,
  },
  admissionsAll: {
    assigned: [],
    startDate: '',
    endDate: '',
    portalPatientStatuses: [],
    search: '',
    statuses: [],
    supervisors: [],
    type: [],
    order: 'updatedAt',
    dir: 'desc',
    perPage: 10,
    page: 1,
  },
  admissionDetails: {
    search: '',
    supervisors: [],
    statuses: [],
    assigned: [],
    startDate: '',
    endDate: '',
    type: [],
    syncWithHha: '',
    order: '',
    dir: '',
    page: 1,
    perPage: 10,
  },
  admissionAttachmentsApp: {
    search: '',
    withComment: '',
    hhaExchange: '',
    date: { from: '', to: '' },
    order: '',
    dir: '',
    page: 1,
    perPage: 10,
  },
  patients: {
    search: '',
    coordinators: [],
    patientStatuses: [],
    portalPatientStatuses: [],
    assigned: [],
    order: 'createdAt',
    dir: 'desc',
    perPage: 10,
    page: 1,
  },
  patientsIncidentReport: {
    assigned: [],
    search: '',
    dueDate: '',
    dateFrom: '',
    dateTo: '',
    statuses: [],
    incidentsType: [],
    dir: 'desc',
    order: 'dueDate',
    perPage: 10,
    page: 1,
  },
  incidents: {
    assigned: [],
    search: '',
    dueDate: '',
    dateFrom: '',
    dateTo: '',
    statuses: [],
    type: [],
    dir: 'desc',
    order: 'dueDate',
    perPage: 10,
    page: 1,
  },
  incident_new: {
    assigned: [],
    search: '',
    dueDate: '',
    dateFrom: '',
    dateTo: '',
    type: [],
    dir: 'desc',
    order: 'dueDate',
    perPage: 10,
    page: 1,
  },
  incident_all: {
    assigned: [],
    search: '',
    dueDate: '',
    dateFrom: '',
    dateTo: '',
    statuses: [],
    type: [],
    dir: 'desc',
    order: 'dueDate',
    perPage: 10,
    page: 1,
  },
  redFlags: {
    search: '',
    status: [],
    hhaStatus: [],
    patientStatus: [],
    type: [],
    perPage: 10,
    order: '',
    dir: '',
    page: 1,
    date: {
      from: '',
      to: '',
    },
    createdAtTo: '',
    createdAtFrom: '',
    lastNotePosted: {
      from: '',
      to: '',
    },
    retentionSpecialist: [],
  },
  redFlagAttachments: {
    search: '',
    withComment: '',
    hhaExchange: '',
    date: { from: '', to: '' },
    order: '',
    dir: '',
    page: 1,
    perPage: 10,
  },
  patientRedFlagsReports: {
    search: '',
    statuses: [],
    types: [],
    perPage: 10,
    order: '',
    dir: '',
    page: 1,
    createdAtTo: '',
    createdAtFrom: '',
  },
  timesheets: {
    search: '',
    caregivers: [],
    statuses: [],
    coordinators: [],
    dateFrom: '',
    dateTo: '',
    patientId: '',
    order: '',
    dir: '',
    page: 1,
    perPage: 10,
  },
  employeeDocuments: {
    statuses: [],
    search: '',
    hhaSync: null as number,
    date: { from: '', to: '' },
    order: '',
    dir: '',
    page: 1,
    perPage: 10,
  },
  employeeNotes: {
    search: '',
    authors: [],
    order: '',
    dir: '',
    page: 1,
    perPage: 30,
  },
  employeeAttachments: {
    search: '',
    withComment: '',
    hhaExchange: '',
    order: '',
    dir: '',
    page: 1,
    perPage: 10,
  },
  employeeAttachmentsApp: {
    search: '',
    withComment: '',
    hhaExchange: '',
    order: '',
    dir: '',
    page: 1,
    perPage: 10,
  },
  patientAdmissions: {
    startDate: '',
    endDate: '',
    search: '',
    types: [],
    statuses: [],
    supervisors: [],
    perPage: 10,
    page: 1,
  },
  patientIncidents: {
    statuses: [],
    incidentTypes: [],
    search: '',
    dueDate: '',
    order: '',
    dir: '',
    page: 1,
    perPage: 10,
  },
  patientAttachments: {
    date: { from: '', to: '' },
    search: '',
    hhaExchange: null as number,
    order: '',
    dir: '',
    page: 1,
    perPage: 10,
  },
  patientNotes: {
    search: '',
    reasons: [],
    authors: [],
    order: 'updatedAt',
    dir: 'desc',
    page: 1,
    perPage: 30,
  },
  patientTimesheets: {
    statuses: [],
    caregivers: [],
    date: '',
    search: '',
    patientId: '',
    order: '',
    dir: '',
    page: 1,
    perPage: 10,
  },
  patientRedFlags: {
    statuses: [],
    types: [],
    lastNoteDate: '',
    search: '',
    order: '',
    dir: '',
    page: 1,
    perPage: 10,
  },
  incidentNotes: {
    search: '',
    authors: [],
    order: 'updatedAt',
    dir: 'desc',
    page: 1,
    perPage: 30,
    notSavePerPage: 0,
  },
  incidentAttachments: {
    search: '',
    hhaExchange: null as number,
    date: { from: '', to: '' },
    order: '',
    dir: '',
    page: 1,
    perPage: 10,
  },
  hospitals: {
    search: '',
    order: 'createdAt',
    dir: 'desc',
    page: 1,
    perPage: 10,
  },
  doctors: {
    search: '',
    order: 'updatedAt',
    dir: 'desc',
    page: 1,
    perPage: 10,
  },
  lessons: {
    categories: [],
    search: '',
    page: 1,
    perPage: 10,
  },
  lessonPlans: {
    search: '',
    order: '',
    dir: '',
    page: 1,
    perPage: 10,
  },
  lessonPlanDetails: {
    search: '',
    isCompleted: null as number,
    order: '',
    dir: '',
    page: 1,
    perPage: 10,
  },
  lessonCategories: {
    search: '',
    order: '',
    dir: '',
    page: 1,
    perPage: 10,
  },
  users: {
    order: '',
    dir: '',
    page: 1,
    perPage: 10,
    search: '',
    roles: [],
  },
  userEmployees: {
    order: '',
    dir: '',
    page: 1,
    perPage: 10,
    search: '',
    departments: [],
  },
  roles: {
    order: '',
    dir: '',
    page: 1,
    perPage: 10,
    search: '',
  },
  medicalDashboard: {
    order: '',
    dir: '',
    page: 1,
    perPage: 10,
    search: '',
    statuses: [],
    caregiverStatuses: [],
    sales: [],
    skillCategories: [],
    assigned: [],
    date: { from: '', to: '' },
  },
  medicalNotes: {
    order: '',
    dir: '',
    page: 1,
    perPage: 30,
    search: '',
    authors: [],
  },
  medicalAttachments: {
    order: '',
    dir: '',
    page: 1,
    perPage: 10,
    search: '',
    hhaExchange: '',
  },
  medicalAttachmentsApp: {
    order: '',
    dir: '',
    page: 1,
    perPage: 10,
    search: '',
    hhaExchange: '',
  },
  medicalDocumentsHistory: {
    order: '',
    dir: '',
    page: 1,
    perPage: 10,
    search: '',
    statuses: [],
    caregiverStatuses: [],
    sales: [],
    skillCategories: [],
    assigned: [],
    date: { from: '', to: '' },
    hhaSync: null as number,
  },
};
